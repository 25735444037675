<template>
  <div :data-filter="computedFilter">
    <select v-model="priceModel">
      <option value="">Any price</option>
      <option value="price:lt">Price is less than</option>
      <option value="price:lte">Price is less than or equal to</option>
      <option value="price:eq">Price is</option>
      <option value="price:gt">Price is more than</option>
      <option value="price:gte">Price is more than or equal to</option>
    </select>
    <br>
    <input
      v-if="priceModel != ''"
      v-model="price"
      type="number"
      placeholder="Price in Japanese Yen"
    >
  </div>
</template>

<script>
  // import SearchFilterBase from './search-filter-base.vue';

  export default {
    data() {
      return {
        priceModel: '',
        price: null
      };
    },
    computed: {
      computedFilter()
      {
        if (this.priceModel == '')
        {
          return '';
        }
        else
        {
          return `${this.priceModel}${this.price}`;
        }
      }
    }
  }

</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-8">
      <search-box-ex />
    </div>

    <div class="col-sm-12 col-md">
      <component
        v-for="(filter, index) in this.searchFilters"
        :key="index"
        :is="filter"
        class="col text-center"
      />
    </div>
  </div>

</template>

<script>
  import SearchBoxEx from './search-box-ex.vue';
  import PriceFilter from './search_filters/price-filter.vue';
  import StockFilter from './search_filters/stock-filter.vue';

  export default {
    components: {
      SearchBoxEx,
      PriceFilter,
      StockFilter
    },
    data() {
      return {
        searchItems: [
        ],
        searchFilters: [
          'PriceFilter',
          'StockFilter'
        ]
      }
    },
    methods: {
      confirmHandler(e) {

      }
    }
  }

</script>

<style type="text/css" lang="sass">
  select
    border: solid 1px #cfcfcf
    padding: 0.2rem

</style>

<template>
  <div v-for="searchOption in searchOptions" class="row px-5">
    <div class="col-sm-10 col-md mb-3">
      <advanced-search-option></advanced-search-option>
    </div>
    <div class="col-sm-2 col-md-1">
      <button
        class="btn col-sm-12 mb-1"
        @click="addOption">Add</button>
      <button
        class="btn col-sm-12 my-1 remove-filter"
        @click="removeOption">Remove</button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button type="submit" class="btn large px-5 btn-primary">Search</button>
    </div>
  </div>
</template>

<script>
  import AdvancedSearchOption from './advanced-search-option.vue';

  export default {
    components: {
      AdvancedSearchOption
    },
    data() {
      return {
        searchOptions: [
          {
            data: {}
          }
        ]
      };
    }
  }
</script>

<style type="text/css" lang="sass">
  button
    border-radius: 0

  .remove-filter
    font-size: 0.8rem
    padding: 0.2rem
    height: auto

</style>

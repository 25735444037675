<template>
  <div>
    <select>
      <option>Either stock status</option>
      <option>In stock</option>
      <option>Out of stock</option>
    </select>
  </div>
</template>

<script>
  export default {}
</script>
